import React from "react";
import { nils } from "../utils/utils.js";
import { twMerge } from "tailwind-merge";
import { Array2D_Table } from "../components/utilityComps.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const head = "resp-text-2 text-center";
const midhead = "resp-text-1 resp-my-2 font-reg";
const semimidhead = "resp-text-0 font-reg leading-7";
const basetext = "resp-text--1 font-thin leading-7";

const PointsList = ({ list: ar }) => {
  return (
    <ol className={basetext}>
      {ar.map(([s, txt, link]) => (
        <CompLi {...{ s, txt, link }} />
      ))}
    </ol>
  );
};
const CompLi = ({ s, txt, link }) => {
  return (
    <li>
      <a
        {...{
          ...(nils(link) ? {} : { href: link }),
        }}
        className={twMerge(
          !nils(link) ? "underline underline-white underline-offset-4" : "",
          "w-full fr-ss resp-gap-2",
        )}
      >
        <span>{s}.</span>
        <div className="whitespace-pre-wrap">{txt}</div>
      </a>
    </li>
  );
};
function Tutorial() {
  let i = 0;
  return (
    <div className="max-h-[calc(800px)] h-[calc(100vh-9rem)] ">
      <div className="h-[2rem]"></div>
      <div className="xs:w-[96vw] lg:w-[60vw] mx-auto">
        <div className={head}>HOW TO GET STARTED ON DNA RACING</div>

        <div class="xs:w-[95vw] lg:w-[50vw] mx-auto bg-white/60 h-[0.1rem] my-2"></div>
        <div class="fc-cc gap-2">
          <p className="font-digi reps-text-1 text-red-500">
            Checkout our Youtube Channel for video guides
          </p>
          <div class="fr-cc my-2 resp-gap-2">
            <Link
              target="_blank"
              to="https://www.youtube.com/@dnaracingbydanshan/videos"
            >
              <div class="fr-sc resp-p-2 resp-px-4 border border-red-500 bg-r2dark/80 font-digi resp-text-2 text-red-500 rounded-lg hover:bg-red-500 hover:text-white transition duration-400">
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="xs:w-[1rem] lg:w-[3rem] resp-pr-2"
                />
                <span>Youtube</span>
              </div>
            </Link>

            <Link
              target="_blank"
              to="https://fbike.dnaracing.run/help?tab=features"
            >
              <div class="fr-sc resp-p-2 resp-px-4 border border-acc4 bg-r2dark/80 font-digi resp-text-2 text-acc4 rounded-lg hover:bg-acc4 hover:text-white transition duration-400">
                <FontAwesomeIcon
                  icon={faQuestion}
                  className="xs:w-[1rem] lg:w-[3rem] resp-pr-2"
                />
                <span>User Guide</span>
              </div>
            </Link>
          </div>
        </div>

        <div class="xs:w-[95vw] lg:w-[50vw] mx-auto bg-white/60 h-[0.1rem] my-2"></div>
        <div class="h-[1rem]"></div>

        <p className={midhead}>Get Started</p>
        <ol className={basetext}>
          {[
            [++i, "Go to dnaracing.run", ""],
            [
              ++i,
              "A request to download Metamask will pop up if you don’t have Metamask. If you have Metamask skip to Step 20",
              "",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <div className="h-[2rem]"></div>
        <p className={midhead}>What is Metamask and why do I need it?</p>
        <p className={basetext}>
          Metamask, a software cryptocurrency wallet, is used for interacting
          with the Ethereum network. Users can utilize a browser extension or
          mobile app to access their Ethereum wallet, which can then be used to
          connect with decentralized applications. DNA Racing utilizes Metamask
          as its main crypto wallet where you can access Polygon to buy bikes,
          skins, and boxes so it’s necessary to install (More information on
          this as you scroll down).
        </p>
        <p className={midhead}>How to Download Metamask</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              "Click on the request and add the extension to Google Chrome",
              "",
            ],
            [
              ++i,
              "Once it is added to Google Chrome, Metamask will pop up and will get you started",
              "",
            ],
            [++i, "Agree to Metamask’s Terms of Use by checking the box", ""],
            [++i, "Click the Create a new wallet option", ""],
            [
              ++i,
              "Click I agree when a screen that says Help us improve Matamask pops up",
              "https://imgur.com/a/n3Eu1aa",
            ],
            [
              ++i,
              "Create a strong password and and tick the checkbox",
              "https://imgur.com/a/Yv0A05S",
            ],
            [++i, "Click Create a New Wallet", "https://imgur.com/a/QTfssbo"],
            [
              ++i,
              "Watch the video on how you can secure your wallet",
              "https://imgur.com/01mSu0Y",
            ],
          ].map(([s, txt, link]) => {
            return <CompLi {...{ s, txt, link }} />;
          })}
        </ol>
        <div className="ml-[5vw] resp-my-2">
          <p className={semimidhead}> What is a Secret Recovery Phrase?</p>
          <ol className={basetext}>
            {[
              [
                "*",
                "Your Secret Recovery Phrase is a 12-word phrase that is the master key to your wallet and your funds",
                "",
              ],
              ["*", "How do I save my Secret Recovery Phrase?", ""],
              ["*", "Save in a password manager", ""],
              ["*", "Store in a safe deposit box", ""],
              ["*", "Write down and store in multiple secret places", ""],
            ].map(([s, txt, link]) => (
              <CompLi {...{ s, txt, link }} />
            ))}
          </ol>
          <p className={semimidhead}>
            Should I share my Secret Recovery Phrase? Never, ever share your
            Secret
          </p>
          <ol className={basetext}>
            {[["*", "Recovery Phrase, not even with MetaMask!", ""]].map(
              ([s, txt, link]) => (
                <CompLi {...{ s, txt, link }} />
              ),
            )}
          </ol>
        </div>
        <ol className={basetext}>
          {[
            [
              ++i,
              "After watching the video, you have two options: 1) Remind me later or 2) Secure my wallet. The latter is recommended.",
              "https://imgur.com/ld615l7",
            ],
            [
              ++i,
              "If you click on Secure my wallet, it will show you your Secret Recovery Phrase",
              "https://imgur.com/pICJAhJ",
            ],
            [++i, "Click on Reveal Secret Recovery Phrase", ""],
            [
              ++i,
              `It will show you a total of 12 words which you need to take note of",
              \tEvery time you log in on your Metamask account, you will need to type in these 12 specific words`,
              "",
            ],
            [++i, "Click Next when you have noted the 12 words", ""],
            [
              ++i,
              "The site will have you fill out the missing words",
              "https://imgur.com/KUUfMnm",
            ],
            [
              ++i,
              "Your Metamask install is complete, click Next and click Got it!",
              "https://imgur.com/n7XSULt",
            ],
            [++i, "The site will take you back to a log-in page", ""],
            [
              ++i,
              "Log in your Metamask account using your Secret Recovery Phrase",
              "",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Connect Metamask to DNA</p>
        <ol className={basetext}>
          {[
            [++i, "Go back to dnaracing.run", "https://www.dnaracing.run/"],
            [
              ++i,
              `Connect your Metamask account with DNA Racing`,
              "https://imgur.com/jbAiCaH",
            ],
            [
              `\t*`,
              `This will allow you to use your Metamask crypto wallet to purchase DNA Racing NFTs such as bikes, skins, and boxes which you will need to enter races`,
              "",
            ],
            [
              ++i,
              "Once your Metamask account is connected, it will redirect you to the FBike Lobby",
              "https://imgur.com/SpAFjyi",
            ],
            [
              `\t*`,
              `The FBike Lobby is where you can view your Races, Vaults, Leaderboards and Market (More details on this as you scroll down)`,
              "",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Buying Polygon Eth</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              "For you to be able to enter races and buy DNA Racing NFTs, you need to have Polygon Eth in your crypto wallet (Metamask)",
              "",
            ],
            [
              ++i,
              `What is Polygon? \n\t\tAlongside the Ethereum blockchain, Polygon is a "layer two" or "sidechain" scaling solution that enables quick transactions and minimal fees. The network\'s native cryptocurrency, called MATIC, is used for staking, paying fees, and other things. MATIC will be the cryptocurrency you use to buy DNA Racing NFTs.`,
              "",
            ],
            [
              ++i,
              "Go to https://global.transak.com to purchase Polygon Eth",
              "https://global.transak.com",
            ],
            [
              ++i,
              "Choose the polygonzkevm network and input how much you want to purchase",
              "https://imgur.com/6fn86rx",
            ],
            [++i, "Click Buy Now to confirm the purchase", ""],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Add Polygon Network to Metamask</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              "Now that you have Polygon Eth available, you need to connect the Polygon Network to your Metamask",
              "",
            ],
            [++i, "Go to https://polygonscan.com", "https://polygonscan.com"],
            [
              ++i,
              "Scroll to the bottom page and click Add polygon network to your Metamask",
              "https://imgur.com/1UWJVgt",
            ],
            [
              ++i,
              "Once you’ve purchased Eth, it should show up on your DNA Racing balance",
              "https://imgur.com/CIEH2cS",
            ],
            [
              ++i,
              "Go to DNA racing and click on Market (MAKE SURE YOU HAVE MATIC, if you don't, then go to Step 35)",
              "https://imgur.com/VIGFKgc",
            ],
            [
              ++i,
              "Browse through available bikes by clicking of the Fbike section",
              "https://imgur.com/eu0eMEz",
            ],
            [
              ++i,
              "Choose the one you like and click Buy Now",
              "https://imgur.com/g10Paxf",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Buying Matic</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              "If it says error and that you need to convert Weth to Matic, follow the instructions in this blog: https://blog.hawku.com/how-to-get-Matic/",
              "https://blog.hawku.com/how-to-get-matic/",
            ],
            [
              ++i,
              "If you follow the steps, it will lead you to https://wallet.polygon.technology/polygon/token-swap?ref=blog.hawku.com",
              "https://wallet.polygon.technology/polygon/token-swap?ref=blog.hawku.com",
            ],
            [
              ++i,
              "Convert your Polygon Eth to Matic",
              "https://imgur.com/CnMhDYA",
            ],
            [
              ++i,
              "Choose how much Matic you want to purchase (1 Matic is plenty)",
              "https://imgur.com/1B6Myeq",
            ],
            [
              ++i,
              "If it says you have insufficient gas balance, click on Top up on Swap For Gas",
              "https://imgur.com/CggG2ga",
            ],
            [
              ++i,
              "Input how much Matic you want and click Transfer (1 Matic is plenty)",
              "https://imgur.com/lprYGUk",
            ],
            [
              ++i,
              "A signature request will pop up, click Sign",
              "https://imgur.com/JVmO46N",
            ],
            [++i, "Allow the transaction to complete", ""],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Buy a Core aka FBike</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              "You now have Matic, so head back to Hawku to buy the bike you want",
              "https://imgur.com/QARLCW5",
            ],
            [
              ++i,
              "Pick the bike you want and click Buy Now or you can make offers on bikes on Hawku",
              "",
            ],
            [
              ++i,
              "Connect your wallet to log in, allow access, and sign",
              "https://imgur.com/QARLCW5",
            ],
            [
              ++i,
              "After that, you have to click Unlock Eth Functionality",
              "https://imgur.com/sbnKHwx",
            ],
            [
              ++i,
              "Click Unlock, and a Metamask notification will pop out",
              "https://imgur.com/EgGMDfO",
            ],
            [++i, "It will ask you the Spending cap request for your Weth", ""],
            [
              ++i,
              "Click on the Max button and hit NEXT",
              "https://imgur.com/d48OXC7",
            ],
            [++i, "Click the Approve button", "https://imgur.com/V2NZAdq"],
            [
              ++i,
              "After that, a Complete Checkout box will pop out",
              "https://imgur.com/dbD8W5S",
            ],
            [
              ++i,
              "Click Confirm Checkout and confirm the purchase",
              "https://imgur.com/x7Kan2k",
            ],
            [
              ++i,
              "A message will pop out with the following content: Congratulations! Your order has been placed and will be submitted to the blockchain!",
              "https://imgur.com/DTsFYJ3",
            ],
            [
              ++i,
              "Reload the site and the bike will now show up in your Vault",
              "",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Customize your Vault</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              `Hover your mouse to the Vault button`,
              "https://imgur.com/3pruwaq",
            ],
            [++i, `Click on the My Vault option`, ""],
            [
              ++i,
              `At the upper right corner, click on the EDIT PROFILE option`,
              "https://imgur.com/3pruwaq",
            ],
            [
              ++i,
              `Rename your vault to whatever you like and then click Apply Changes`,
              "",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>Add a Skin and Trail</p>
        <ol className={basetext}>
          {[
            [++i, `Go to your vault`, "https://imgur.com/upYgwO8"],
            [++i, `Click any of the 3D buttons`, ""],
            [
              "\t>>",
              `RED RECTANGLES are the skins you own.`,
              "https://imgur.com/NzGekQt",
            ],
            [
              "\t>>",
              `YELLOW RECTANGLES are bikes you own.`,
              "https://imgur.com/NzGekQt",
            ],
            [
              "\t>>",
              `BLUE RECTANGLES are where you can see if the bike already has applied skin on.`,
              "https://imgur.com/NzGekQt",
            ],
            [
              ++i,
              `If you click on any of the bikes, it will appear in the 3D window.`,
              "https://imgur.com/Itb97mi",
            ],
            [
              ++i,
              `You can choose which skin you like and it will show on the bike.`,
              "https://imgur.com/824dC9C",
            ],
            [
              "\t>>",
              `If you like how the bike looks with the skin, click APPLY and Metamask will pop open to apply it.`,
              "",
            ],
            [
              ++i,
              `Click on the TRAIL BUTTON as seen above to see the trail options.`,
              "https://imgur.com/o8wxlVx",
            ],
            [
              "\t>>",
              `You are not required to add trails, but you can if you wish.`,
              "",
            ],
            [
              "\t>>",
              `Once you click a trail, you will see the trails on the 3D previewer.`,
              "",
            ],
            [
              ++i,
              `If you like the trails you see in the previewer, hit APPLY.`,
              "https://imgur.com/o8wxlVx",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>
        <p className={midhead}>How to Mint a Skin</p>
        <ol className={basetext}>
          {[
            [++i, `Go to dnaracing.run`, "http://dnaracing.run"],
            [++i, `Click on Skins`, "http://dnaracing.run"],
            [++i, `Select Mint Skin Now`, "https://imgur.com/fLyhBXf"],
            [++i, `Connect your Metamask wallet`, "https://imgur.com/6CC1DF6"],
            [++i, `Click Next and then Connect`, "https://imgur.com/G7wwIjz"],
            [++i, `Click Sign`, "https://imgur.com/XopZfqq"],
            [++i, `Now buy a Skin Mint Box`, ""],
            [++i, `Click Buy Now`, "https://imgur.com/XEkPQ8m"],
            [++i, `Select the quantity you want`, "https://imgur.com/HsZOMZB"],
            [++i, `Click Pay Now`, "https://imgur.com/rhl3Xu0"],
            [
              ++i,
              `Confirm the transaction on Metamask`,
              "https://imgur.com/jXJ36fz",
            ],
            [
              ++i,
              `Click Confirm and a confirmation message will pop out from Metamask`,
              "https://imgur.com/jXJ36fz",
            ],
            [
              ++i,
              `Open your skin box by clicking on it once`,
              "https://imgur.com/4S9uf0t",
            ],
            [++i, `Click confirm on the metabox pop outs`, ""],
            [
              ++i,
              `At the bottom of the page, it will show that you’ve minted 1 box`,
              "https://imgur.com/PMcDxJ2",
            ],
            [
              ++i,
              `As you open the box, the bottom of the page will also show that a box has been opened`,
              "https://imgur.com/K5ySrvs",
            ],
            [
              ++i,
              `If you click on the View NFT message beside it, you will be able to see your skin`,
              "https://imgur.com/FbACpyX",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>

        <p className={midhead}>How to Enter a Race</p>
        <ol className={basetext}>
          {[
            [++i, `Go to your Vault`, "https://imgur.com/oD20RR0"],
            [
              ++i,
              `Click the down arrow on your bike card`,
              "https://imgur.com/IM9cDcP",
            ],
            [
              ++i,
              `Under it is the Class your bike is in for that specific distance`,
              "",
            ],
            [++i, `Decide which distance you want to race in`, ""],
            [
              ++i,
              `Hover your mouse over the Races button and click on Main Races`,
              "https://imgur.com/ysRQRo2",
            ],
            [
              ++i,
              `Use the filters to choose the Class and Distance you want to race in`,
              "",
            ],
            [
              ++i,
              `For the format, you can choose between the 5 options provided (Check Race Types and Up Classing just below this topic)`,
              "",
            ],
            [
              ++i,
              `It shall also be your choice whether you want to enter a free or paid race`,
              "",
            ],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>

        <p className={midhead}>Entering a Free Race</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              `Once your filters are all applied, choose a race at the very bottom and click Enter Race`,
              "https://imgur.com/jx0EMMH",
            ],
            [
              ++i,
              `Choose which Bike you want to use`,
              "https://imgur.com/KkPZUbM",
            ],
            [++i, `Choose which race you want to join and hit Enter Race`, ""],
            [++i, `Choose the bike you want to race in`, ""],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>

        <p className={midhead}>Entering a Paid Race</p>
        <ol className={basetext}>
          {[
            [
              ++i,
              `For a paid race, choose the Distance and Class`,
              "https://imgur.com/l4xyOj9",
            ],
            [
              ++i,
              `Click on Paid in the Race Fee section`,
              "https://imgur.com/lCFH88Z",
            ],
            [
              ++i,
              `Available races can be seen at the bottom`,
              "https://imgur.com/lCFH88Z",
            ],
            [++i, `Choose which race you want to participate in`, ""],
            [++i, `Click Enter Race`, "https://imgur.com/pxHkAqU"],
            [
              ++i,
              `Choose the Bike you want to race in`,
              "https://imgur.com/u3AfXjT",
            ],
            [++i, `A Metamask will pop out`, "https://imgur.com/AfMvP8h"],
            [
              ++i,
              `Click on Max as your spending cap`,
              "https://imgur.com/m92koQh",
            ],
            [
              ++i,
              `Hit Next and then click Approve`,
              "https://imgur.com/xbXHStj",
            ],
            [++i, `Confirm the transaction`, ""],
            [
              ++i,
              `A Metamask message will appear at the bottom right corner of the screen`,
              "https://imgur.com/eHOQ2q0",
            ],
            [++i, `You’ve officially entered a Paid Race`, ""],
          ].map(([s, txt, link]) => (
            <CompLi {...{ s, txt, link }} />
          ))}
        </ol>

        <div className="h-[2rem]"></div>
        <p className={head}>General Information about DNA Racing Elements</p>

        <a
          className="underline underline-white underline-offset-4"
          href="https://imgur.com/ysRQRo2"
        >
          <p className={midhead}>{"Race Types and Up Classing"}</p>
        </a>

        <PointsList
          list={[
            [
              "1",
              <>
                <p className={semimidhead}>Normal</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      [
                        "a",
                        <>
                          <p className={semimidhead}>1v1</p>
                          <div className="ml-[3vw]">
                            <PointsList
                              list={[
                                [
                                  "*",
                                  `On a 1V1 match, you need 1 win to move up a class`,
                                  "",
                                ],
                                [
                                  "*",
                                  `For example: If you enter 3 races and win 2 of them, you’ll move up 2 classes`,
                                  "",
                                ],
                              ]}
                            />
                          </div>
                        </>,
                        "",
                      ],
                      [
                        "a",
                        <>
                          <p className={semimidhead}>Sit N Go</p>
                          <div className="ml-[3vw]">
                            <PointsList
                              list={[
                                [
                                  "*",
                                  `Sit N Go does not affect your class status`,
                                  "",
                                ],
                                ["*", `You neither win or lose`, ""],
                              ]}
                            />
                          </div>
                        </>,
                        "",
                      ],
                      [
                        "a",
                        <>
                          <p className={semimidhead}>Spin N Go</p>
                          <div className="ml-[3vw]">
                            <PointsList
                              list={[
                                ["*", `Every win moves you 1 class up`, ""],
                                [
                                  "*",
                                  `For example: If you enter 3 races and win 2 of them, you’ll move up 2 classes`,
                                  "",
                                ],
                              ]}
                            />
                          </div>
                        </>,
                        "",
                      ],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />
        <PointsList
          list={[
            [
              "2",
              <>
                <p className={semimidhead}>Reduced</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      [
                        "*",
                        `Low entry races are mainly used by players as a means to strategize for them to land in a stronger position`,
                        "",
                      ],
                      ["*", `Every win moves you 1 class higher`, ""],
                      ["*", `You move down a class every 3 losses`, ""],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />
        <PointsList
          list={[
            [
              "3",
              <>
                <p className={semimidhead}>Free</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      [
                        "*",
                        `Free races do not affect class status
                        `,
                        "",
                      ],
                      ["*", `You neither win nor lose`, ""],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />
        <PointsList
          list={[
            [
              "3",
              <>
                <p className={semimidhead}>Downclassing</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      [
                        "*",
                        `1 yellow triangle means that you have 1 down race (A race out of the money) and need 2 more to drop a class at that specific distance                        `,
                        "",
                      ],
                      [
                        "*",
                        `2 red hazards mean you have 2 down races and need 1 more to drop a class at that specific distance`,
                        "",
                      ],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />
        <PointsList
          list={[
            [
              "4",
              <>
                <p className={semimidhead}>Aging</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      ["*", `No races currently count towards aging `, ""],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />
        <PointsList
          list={[
            [
              "3",
              <>
                <p className={semimidhead}>Stars</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      [
                        "*",
                        `A blue star means you have the highest win count in the race simulation`,
                        "",
                      ],
                      [
                        "*",
                        `A yellow star means you have the highest podium rate in the simulation`,
                        "",
                      ],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />

        <PointsList
          list={[
            [
              "3",
              <>
                <p className={semimidhead}>Spin N Go System</p>
                <div className="ml-[3vw]">
                  <PointsList
                    list={[
                      [
                        "*",
                        `You enter a $1 dollar entry fee for Spin N Go`,
                        "",
                      ],
                      ["*", `3 people enter, making it $3 per race`, ""],
                      [
                        "*",
                        `Once the race is filled, a randomized pot is assigned based on one of the following multipliers:`,
                        "",
                      ],
                      [
                        "*",
                        <>
                          <p>Multipliers</p>
                          <div className="ml-[2vw]">
                            <PointsList
                              list={[
                                ["- ", "240", ""],
                                ["- ", "120", ""],
                                ["- ", "25", ""],
                                ["- ", "10", ""],
                                ["- ", "5", ""],
                                ["- ", "3", ""],
                                ["- ", "2", ""],
                              ]}
                            />
                          </div>
                        </>,
                        "",
                      ],
                      ["*", `Example: Multiplier 3 = $1 x 3 = $3`, ""],
                      [
                        "*",
                        `If it lands on Multiplier 10, that would be $1 x 10 = $10 payout`,
                        "",
                      ],
                      [
                        "*",
                        `The actual price pool of your race is assigned as soon as the race is scheduled`,
                        "",
                      ],
                      [
                        "*",
                        `Click the prize pool dropdown symbol  to see the race prize pool before it starts`,
                        "",
                      ],
                      [
                        "*",
                        `The system is generally based around the following: https://www.pokernews.com/strategy/spin-and-go-poker-36231.htm`,
                        "https://www.pokernews.com/strategy/spin-and-go-poker-36231.htm",
                      ],
                    ]}
                  />
                </div>
              </>,
              "",
            ],
          ]}
        />

        <p className={midhead}>The Race Lobby</p>
        <PointsList
          list={[
            [
              "",
              `The Race Lobby is what you see when you view the Main Races section. It is divided into three parts:`,
              "",
            ],
            ["1", `Left-side panel`, ""],
            ["2", `Center panel; and`, ""],
            ["3", `Right-side panel`, ""],
            [
              "",
              <div className="ml-[2vw]">
                <a
                  className="underline underline-white underline-offset-4"
                  href="https://imgur.com/RaM4D9a"
                >
                  <p className={midhead}>{"A) Left-Side Panel"}</p>
                </a>
                <PointsList
                  list={[
                    [
                      "",
                      `At the left side of your screen is a panel which has three categories:`,
                      "",
                    ],
                    [
                      "",
                      <div className="ml-[2vw]">
                        <PointsList
                          list={[
                            [
                              "*",
                              `Open Races are races which you have entered but aren’t filled yet`,
                              "",
                            ],
                            [
                              "*",
                              `Scheduled Races are races which you have entered and are already filled. Once the race begins, a timer will start. You can click on the race to watch it in 2D or 3D`,
                              "",
                            ],
                            [
                              "*",
                              `Finished Races are races which are already finished. You can click on them to watch the race and see the results.`,
                              "",
                            ],
                          ]}
                        />
                      </div>,
                    ],
                  ]}
                />
              </div>,
              "",
            ],
            [
              "",
              <div className="ml-[2vw]">
                <a
                  className="underline underline-white underline-offset-4"
                  href="https://imgur.com/og6EZFT"
                >
                  <p className={midhead}>{"B) Center Panel"}</p>
                </a>
                <PointsList
                  list={[
                    [
                      "",
                      <div className="ml-[2vw]">
                        <PointsList
                          list={[
                            [
                              "*",
                              `The Center Panel is where you can add filters to specify which type of race you want to enter`,
                              "",
                            ],
                            ,
                            [
                              "*",
                              `These filters include 1) Distance 2) Class 3) Gates 4) Payout 5) Formats and 6) Race Fee`,
                              "",
                            ],
                            [
                              "",
                              <>
                                <div className="ml-[2vw]">
                                  <p className={midhead}>Distances</p>
                                  <p
                                    className={basetext}
                                  >{`The Distances determine how many meters the bikes will have to race until the finish line. The Distances are classified into the following options:`}</p>
                                  <PointsList
                                    list={[
                                      [">>", `CB1000 (1000 meters)`, ""],
                                      [">>", `CB1200 (1200 meters)`, ""],
                                      [">>", `CB1400 (1400 meters)`, ""],
                                      [">>", `CB1600 (1600 meters)`, ""],
                                      [">>", `CB1800 (1800 meters)`, ""],
                                      [">>", `CB2000 (2000 meters)`, ""],
                                      [">>", `CB2200 (2200 meters)`, ""],
                                    ]}
                                  />
                                </div>
                              </>,
                              "",
                            ],
                            [
                              "",
                              <>
                                <div className="ml-[2vw]">
                                  <p className={midhead}>Classes</p>
                                  <p
                                    className={basetext}
                                  >{`Classes are designed to ensure that bikes are racing against other bikes with the same ability. The Classes are categorized into:`}</p>
                                  <PointsList
                                    list={[
                                      [">>", `Class 1`, ""],
                                      [">>", `Class 2`, ""],
                                      [">>", `Class 3`, ""],
                                      [">>", `Class 4`, ""],
                                      [">>", `Class 5`, ""],
                                      [">>", `Tourney`, ""],
                                    ]}
                                  />
                                </div>
                              </>,
                              "",
                            ],
                            [
                              "",
                              <>
                                <div className="ml-[2vw]">
                                  <p className={midhead}>Gates</p>
                                  <p
                                    className={basetext}
                                  >{`The Gate number indicates which lane a bike will start from in the race. Options for Gates include:`}</p>
                                  <PointsList
                                    list={[
                                      [
                                        ">>",
                                        `2 Gates (2 bikes in a single race)`,
                                        "",
                                      ],
                                      [
                                        ">>",
                                        `3 Gates (3 bikes in a single race)`,
                                        "",
                                      ],
                                      [
                                        ">>",
                                        `4 Gates (4 bikes in a single race)`,
                                        "",
                                      ],
                                      [
                                        ">>",
                                        `6 Gates (6 bikes in a single race)`,
                                        "",
                                      ],
                                      [
                                        ">>",
                                        `7 Gates (7 bikes in a single race)`,
                                        "",
                                      ],
                                      [
                                        ">>",
                                        `8 Gates (8 bikes in a single race)`,
                                        "",
                                      ],
                                      [
                                        ">>",
                                        `10 Gates (10 bikes in a single race)`,
                                        "",
                                      ],
                                    ]}
                                  />
                                </div>
                              </>,
                              "",
                            ],
                            [
                              "",
                              <>
                                <div className="ml-[2vw]">
                                  <p className={midhead}>Payout</p>
                                  <p
                                    className={basetext}
                                  >{`For the Payout system you can choose any of the following:`}</p>
                                  <PointsList
                                    list={[
                                      [">>", `Na (Free)`, ""],
                                      [">>", `WTA`, ""],
                                      [">>", `Top 2`, ""],
                                      [">>", `Top 3`, ""],
                                      [">>", `Top 4`, ""],
                                      [">>", `DBL Up`, ""],
                                      [">>", `Pity`, ""],
                                      [">>", `Pity low`, ""],
                                    ]}
                                  />
                                  <p
                                    className={basetext}
                                  >{`The following table shows the following Payout percentage for each format and finish position:`}</p>
                                  <Array2D_Table
                                    ar={[
                                      [
                                        "RACING FORMAT",
                                        "FINISH POSITION",
                                        "PAYOUT %",
                                      ],
                                      ["1v1", "1", "100%"],
                                      ["WTA", "1", "100%"],
                                      ["TOP 2", "1", "70%"],
                                      ["", "2", "30%"],
                                      ["TOP 3", "1", "65%"],
                                      ["", "2", "25%"],
                                      ["", "3", "10%"],
                                      ["TOP 4", "1", "25%"],
                                      ["", "2", "25%"],
                                      ["", "3", "25%"],
                                      ["", "4", "25%"],
                                      ["HYBRID", "1", "47.4%"],
                                      ["", "3", "26.3%"],
                                      ["", "5", "26.3%"],
                                      ["HEAVY", "1", "60%"],
                                      ["", "2", "20%"],
                                      ["", "3", "20%"],
                                      ["PITY LOW", "4", "20%"],
                                      ["", "5", "60%"],
                                      ["", "6", "20%"],
                                      ["PITY", "2", "20%"],
                                      ["", "3", "60%"],
                                      ["", "4", "20%"],
                                    ]}
                                  />
                                </div>
                              </>,
                              "",
                            ],
                            [
                              "",
                              <>
                                <div className="ml-[2vw]">
                                  <p className={midhead}>Format</p>
                                  <p
                                    className={basetext}
                                  >{`For the format, you can choose among the following:`}</p>
                                  <PointsList
                                    list={[
                                      [">>", `Normal`, ""],
                                      [">>", `Spin N Go`, ""],
                                      [">>", `Sit N Go`, ""],
                                      [">>", `Reduced`, ""],
                                      [">>", `Rounds`, ""],
                                    ]}
                                  />
                                </div>
                              </>,
                              "",
                            ],
                            [
                              "",
                              <>
                                <div className="ml-[2vw]">
                                  <p className={midhead}>Race Fee</p>
                                  <p
                                    className={basetext}
                                  >{`For the format, you can choose among the following:`}</p>
                                  <PointsList
                                    list={[
                                      [
                                        "*",
                                        `If you want to join a Paid Race, you can filter out the minimum and maximum amount you want to spend`,
                                        "",
                                      ],
                                      [
                                        "*",
                                        `Races which fit your criteria will show up after you’ve set your filters`,
                                        "",
                                      ],
                                    ]}
                                  />
                                </div>
                              </>,
                              "",
                            ],
                          ]}
                        />
                      </div>,
                    ],
                  ]}
                />
              </div>,
              "",
            ],
            [
              "",
              <div className="ml-[2vw]">
                <a
                  className="underline underline-white underline-offset-4"
                  href="https://imgur.com/6GEI2u8"
                >
                  <p className={midhead}>A) Right Panel</p>
                </a>
                <div className="ml-[2vw]">
                  <PointsList
                    list={[
                      [
                        "*",
                        `The Right-side panel is where you can view you Bike List`,
                        "",
                      ],
                      [
                        "*",
                        `This is also the panel where you can filter the Bikes you want to use to enter a competition`,
                        "",
                      ],
                      [
                        "*",
                        `Click the All section of the Bike List if you want to view all of the bikes you own`,
                        "",
                      ],
                      [
                        "*",
                        `Click the Hide button beside a bike to remove it from the list of bikes you can use to enter a race `,
                        "",
                      ],
                      [
                        "*",
                        `You can also decide which Distances a certain bike can only be used on`,
                        "",
                      ],
                      [
                        "\t\t",
                        `Example: If you click on one of your bikes and chose CB1000, that bike will only be available on races you enter that  has CB1000 as the distance limit`,
                        "",
                      ],
                      [
                        "*",
                        `All bikes you’ve put a filter on will show up in the Filter section of the Bike List`,
                        "",
                      ],
                    ]}
                  />
                </div>
              </div>,
              "",
            ],
          ]}
        />
      </div>
      <div className="h-[12rem]"></div>
    </div>
  );
}

export default Tutorial;
