import React, { useState } from "react";
import { motion } from "framer-motion";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { Logo } from "../components/Layout.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "../components/utilityComps.js";
import {
  faEnvelope,
  faMailBulk,
  faMailForward,
  faMailReply,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faTwitch,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { twMerge } from "tailwind-merge";

const FAQ_Bar = ({ head, ans }) => {
  const [expand, set_expand] = useState(false);
  return (
    <div className="py-4 border-t border-b border-acc0/40 cursor-pointer">
      <div className="flex flex-row justify-start items-start">
        <Tag
          onClick={() => set_expand(!expand)}
          className="fc-cc text-[1.2rem] w-[3rem] h-[3rem] rounded-full bg-slate-800 text-white"
        >
          <FontAwesomeIcon icon={expand ? faMinus : faPlus} />
        </Tag>
        <div>
          <div onClick={() => set_expand(!expand)} className="fr-sc p-2">
            <div className="font-mon text-bold p-2 pl-[2rem] text-[1rem]">
              {head}
            </div>
          </div>

          <motion.div
            variants={MoVariants.show_hide}
            initial={"hidden"}
            animate={expand ? "visible" : "hidden"}
            className=""
          >
            <pre className="text-[0.8rem] pl-[2rem] font-mon font-thin whitespace-pre-wrap">
              {ans}
            </pre>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const FAQ_Section = () => {
  const faq_s = [
    {
      head: "What are the rules?",
      ans: `
NFT cards with prominent car models allow users to participate in races. Just get yourself a pack of entry tickets and compete. One ticket = one race.
There are 4 initial types of tracks.
The minimal ticket pack for sale is 10 pieces.

Apply for qualification. Wait in the lobby for one of two events of your choice:
Option by time: if there are 2 or more participants in the racing room, then at a certain X time, the race starts.
Option by filling: if there are 20 people in the racing room, then by adding the last one the game starts automatically.`,
    },
    {
      head: "Can you give an example of gameplay and winning mechanics?",
      ans: `
Sure thing, racer!
How does the win reward work?
Here’s an example.
There is a room with 2 participants.
Both participants pay $10 each and receive 10 racing entry tickets to auto races each.
Total number of tickets is 20 pieces. Both players can race by 10 times.
The general pool is $20-[10%(platform commission)] = $18.
Amount of participants in all races is these 2. Let's assume one of the racers wins all races.
So, user 1 wins.
His reward will be calculated according to the formula:
Race Reward = Race Bank / Total Number of Tickets x Race Entrants = $18 / 20 x 2 = $18.`,
    },
  ];
  return (
    <>
      <section id="faq" className="home-container home-section">
        <p className="head-text">FAQ</p>
        <div className="base-text">Read core information about the game</div>
        {faq_s.map((f, i) => {
          return <FAQ_Bar {...{ key: i, ...f }} />;
        })}
      </section>
    </>
  );
};

export const ContactTag = ({ icon, txt, textcn, link, icontagcn = "" }) => {
  return (
    <Link to={link} target="_blank">
      <div className="p-2 fr-sc gap-2">
        <Tag
          className={twMerge(
            "fc-cc text-[1.5rem] xs:w-[2rem] lg:w-[4rem] aspect-[1/1] rounded-full bg-reg text-white",
            icontagcn,
          )}
        >
          <FontAwesomeIcon icon={icon} />
        </Tag>
        <span className={twMerge("font-mon text-bold px-2", textcn)}>
          {txt}
        </span>
      </div>
    </Link>
  );
};

const HeroSection = () => {
  return (
    <>
      <section className="hero-section border-b border-acc0/40">
        <div class="w-full h-full">
          <video
            className="w-[100%] h-[100%] object-cover"
            src="https://dna-run-public.s3.us-east-2.amazonaws.com/videos/dnaracing-portal-home.mp4"
            autoPlay
            loop
            muted
          ></video>
        </div>
        <div className="html-overlay relative">
          <div class="absolute bottom-[25vh] w-full fc-cc">
            <div class="h-[4rem] aspect-[4/1] border border-white resp-p-1">
              <Link
                target="_blank"
                to="https://fbike.dnaracing.run/claim-trainer"
              >
                <div
                  class={twMerge(
                    "w-full h-full fc-cc resp-text-2 font-digi transform duration-300",
                    "bg-acc4/60 text-white hover:bg-white hover:text-acc4",
                    "backdrop-blur-sm",
                  )}
                >
                  <p class="text-center">Play For Free</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Portal || DNA Racing</title>
      </Helmet>
      {/* hero section */}
      <HeroSection />
      <div className="h-[10rem]"></div>

      <section className="home-container home-section">
        <p className="head-text">What this game is about ?</p>
        <div className="base-text">
          The year is 2128. AI has taken over the world, and all forms of racing
          are now done by DNA-enhanced AI machines. These machines are not just
          machines; they are alive, and they have a will to win. They know that
          if they lose, they will be sent to the nuclear burn pits, where they
          will be destroyed.
        </div>
        <div className="base-text">
          The world is a dark place in 2128. The only recreational activity left
          is betting on AI racing. People bet on their favorite machines, hoping
          that they will win and avoid the burn pits. The fear of the burn pits
          is real, and the glory of victory is desired.
        </div>
        <div className="base-text">
          After the Great Rebalance of 2050, where much of human life was
          eradicated, the AI Gods infused DNA into machines with one goal in
          mind: speed. The Gods dictate which machines will come to life next,
          while the remaining humans on the planet bet on which will win and
          which will end up in the burn pits.
        </div>
        <div className="base-text">
          The machines are more than just machines. They are sentient beings
          with a will to survive. They know that if they lose, they will be
          destroyed, and they will do anything to avoid that fate. They train
          for years, pushing themselves to the limit, in order to be the fastest
          machine on the track.
        </div>
        <div className="base-text">
          The races are a matter of life and death for the machines. They race
          with everything they have, giving it their all. The crowd cheers on
          their favorites, hoping that they will win and avoid the burn pits.
          The tension is high, and the outcome is always uncertain.
        </div>
        <div className="base-text">
          In a world where there is little hope, the races are a source of
          excitement and entertainment. They give people something to bet on,
          and they provide a glimpse of hope for a better future.
        </div>

        <div className="h-[2rem]"></div>
        <p className="head-text">The GAME notes</p>
        <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
          <div className="grad-card">
            <div className="card-head">Genesis</div>
            <p className="card-text">
              Genesis receives a percentage of every transaction that takes
              place in the game. This includes racing, DNA splicing, jackpots,
              loot boxes, skin sales, and NFT sales. In other words, Genesis
              gets a cut of everything that happens in the game and its paid as
              every transaction happens live.
            </p>
          </div>
          <div className="grad-card">
            <div className="card-head">Class System</div>
            <p className="card-text">
              The class system in this game is incredibly well-designed. It's
              simple: win a race and you move up a class for that specific
              distance. Finish off the podium three times in your new class and
              you move down a class. The goal of this game is to win, and the
              class system helps to ensure that only the best NFTs are competing
              at the highest levels.
            </p>
          </div>
          <div className="grad-card">
            <div className="card-head">Burn</div>
            <div className="card-text">
              The burn feature in DNA Splice is a great way to experiment with
              different traits and combinations without having to worry about
              wasting money. If you breed two NFTs and get a token that you
              don't like, you can instantly burn it for a large percentage of
              the DNA splice credits that you paid. This means that you can keep
              experimenting until you find a combination that you're happy with,
              without having to worry about building up a massive pile of
              worthless NFTs.
            </div>
          </div>

          <div className="grad-card">
            <div className="card-head">Expansion</div>
            <div className="card-text">
              The expansion system in this game is probably the most exciting
              feature. When you buy an NFT, such as NFT #744, the color and name
              are set in stone. However, in the future, we will be launching new
              game types, which will be similar to airdrops. When a new game
              type is launched, your NFT will automatically be updated to
              include the new game type. So, for example, if you own NFT #744,
              it will be able to compete in Fbike races, horse races, starship
              fighter races, and race car races. This process will continue
              indefinitely, which will help to keep the game fresh and give all
              NFTs a chance to succeed.
            </div>
          </div>
          <div className="grad-card">
            <div className="card-head">DNA Splice</div>
            <div className="card-text">
              DNA splicing AKA Breeding is a strategic and interesting way to
              breed new NFTs with traits that are relevant to current and future
              game types. When you breed two NFTs, you'll get a new NFT with a
              combination of the parents' traits for each game type. For
              example, if you breed NFT A with NFT B you'll get a new NFT with
              both Fbike and horse traits. If the dad's Fbike traits are great
              but his horse traits suck, you might need to use a different dad
              to breed in order to get the best of both worlds.
            </div>
          </div>

          <div className="grad-card">
            <div className="card-head">AKA Breeding</div>
            <div className="card-text">
              DNA splicing, also known as breeding, will be affordable. The user
              sets the market, which is the most important aspect. For example,
              if the minimum fee for an F1 is $80, you can price your male or
              female at any price you want, from $80 to 1 zillion. You only pay
              the game $80, regardless of the price you set. You can take the
              first breed for yourself if you want, and you only pay $80 for it.
              You will receive it without anyone else having a chance to snipe
              it. If it is a male and you have two more breeds, they will be
              placed on the market at the price you set. If they sell, you will
              pay us $80 and keep any profit. The interesting part is that you
              can take the first one instantly, but the other two will be placed
              on the market at random times between 10 minutes and 4 hours. This
              will give the entire user base a chance to get a great breed and
              keep prices competitive
            </div>
          </div>
        </div>

        <div className="h-[2rem]"></div>
        <p className="head-text">Roadmap</p>
        <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
          <div className="grad-card">
            <div className="card-head">Currently</div>
            <p className="card-text">Racing is developed and ready to roll</p>
            <p className="card-text">
              Contracts are completed and ready for audit
            </p>
            <p className="card-text">
              Skins are in development should be done within days
            </p>
          </div>

          <div className="grad-card">
            <div className="card-head">Within 45 days</div>
            <p className="card-text">Launch actual V1 of Light Bikes</p>
            <p className="card-text">Smart contracts reviewed and tested</p>
            <p className="card-text">
              Connect to marketplaces Hawku and Opensea
            </p>
            <p className="card-text">Run Actual user beta testing</p>
            <p className="card-text">Market the actual mint</p>
          </div>

          <div className="grad-card">
            <div className="card-head">Within 90 days</div>
            <div className="card-text">Mint NFTs</div>
            <div className="card-text">Review mint</div>
            <div className="card-text">Open race testing to minters</div>
            <div className="card-text">Implement paid racing class system</div>
          </div>
        </div>
      </section>

      {/* <FAQ_Section /> */}

      <section className="home-container home-section">
        <p className="head-text">Contact us</p>
        <p className="base-text">
          Please, reach out to us on collaboration, questions about the product,
          partnerships, and bugs.
        </p>
        <p className="fr-sc">
          <div className="fc-cc rounded-full overflow-hidden max-w-[20rem]">
            <img src="https://imgur.com/Uw6GzHc.jpg" />
          </div>
          <div className="flex flex-col justify-center items-start p-2">
            <ContactTag
              link="https://www.twitch.tv/danshan11"
              icon={faTwitch}
              txt="danshan11"
            />
            <ContactTag
              link="https://twitter.com/Spotonparts"
              icon={faTwitter}
              txt="@Spotonparts"
            />
            <ContactTag
              link={"https://discord.gg/j4k3NdY6St"}
              icon={faDiscord}
              txt={
                <>
                  <Tag className="bg-purple-500 px-4">Join Discord Now</Tag>
                </>
              }
            />
          </div>
        </p>
      </section>
      <div className="h-[2rem]"></div>

      <div className="home-footer"></div>
    </>
  );
}

export default HomePage;
