import { useMatch } from "react-router";
import { useQueries, useQueryClient } from "react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { Card, Img, Tag } from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import skinsmap from "../data/skinsmap.json";
import skin_thumbnail from "../data/skin_thumbnail.jpg";
import "intersection-observer";
import { getv, nils } from "../utils/utils.js";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { set_state_ob } from "../components/input.js";
import { RVImg } from "../components/BikeImg.js";

const skin_baseurl_map = {
  bike: "https://cdn.dnaracing.run/fbike-skins/",
  car: "https://cdn.dnaracing.run/car-skins/",
};

export const gets_skinurl = (skin, rvmode) => {
  if (nils(skin)) return null;
  const baseURL = skin_baseurl_map[rvmode];
  const formattedSkin = skin.replace(/ /g, "+");
  const newImageURL = `${baseURL}${formattedSkin}/${formattedSkin}.jpg`;
  return newImageURL;
};

export const skin_thumbnail_map = {
  bike: "https://cdn.dnaracing.run/imgs/bike_skin_thumbnail.png",
  car: "https://cdn.dnaracing.run/imgs/car_skin_thumbnail.png",
};

export const SkinCard = ({
  skin,
  rvmode,
  rarity: cat,
  activeskin,
  idx,
  viewdets = false,
  namecn,
}) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);
  const url = gets_skinurl(skin, rvmode ?? "bike");

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            setLoaded(true);
          };
          observer.disconnect();
        }
      });
    });
    observer.observe(imgRef.current);
    return () => observer.disconnect();
  }, [url]);

  const active = activeskin == skin;
  const blur = !nils(activeskin) && activeskin !== false && !active;

  const getTransformOriginClass = (idx) => {
    let i = idx % 3;
    switch (i) {
      case 0:
        return "origin-left"; // If you're using Tailwind v2.2.0 and above
      case 2:
        return "origin-right"; // If you're using Tailwind v2.2.0 and above
      default:
        return "origin-center";
    }
  };
  const transformorigin = getTransformOriginClass(idx);

  return (
    <Card
      className={
        "xs:p-0 xs:m-0 md:p-0 md:m-0 xs:w-[8rem] md:w-full mx-auto relative overflow-visible"
      }
    >
      <div
        style={{
          background: `url(${skin_thumbnail_map[rvmode]})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className={twMerge(
          "img-obey-cont h-auto w-full ",
          "aspect-[2200/1600] w-full trasition duration-300",
          transformorigin,
          blur ? "" : "",
          active
            ? "absolute xs:scale-[1] md:scale-[1.1] 2xl:scale-[1.1] z-[20]"
            : "",
        )}
      >
        {
          <div
            className={twMerge(
              "absolute w-full h-full fc-cc transition duration-500",
              active || viewdets ? "opacity-100" : "opacity-0",
            )}
          >
            <div className="w-full h-[70%]"></div>
            <div className="w-full h-[30%] bg-gradient-to-b from-transparent to-dark/40">
              <div className="flex flex-row justify-start items-end w-full h-full text-black p-4 gap-4">
                <div
                  className={twMerge(
                    "flex-1 font-digi text-white italic",
                    skin && skin.length > 10
                      ? "xs:text-[0.35rem] md:text-[0.6rem] 2xl:text-[0.7rem]"
                      : "xs:text-[0.5rem] md:text-[1rem] 2xl:text-[1.1rem]",
                    namecn,
                  )}
                >
                  {skin}
                </div>
                <div className="font-digi xs:text-[0.4rem] md:text-[0.7rem] 2xl:text-[0.8rem]">
                  {_.upperCase(cat)}
                </div>
              </div>
            </div>
          </div>
        }
        <img
          ref={imgRef}
          src={loaded ? url : ""}
          alt={skin} // Recommended to provide an alt attribute for accessibility
          loading="lazy"
          thumbnailSrc={skin_thumbnail}
          style={loaded ? {} : { visibility: "hidden" }}
        />
      </div>
    </Card>
  );
};

export const Skins = () => {
  const [activeskin, set_activeskin] = useState(null);
  const [activecat, set_activecat] = useState(null);
  const [rvmode, set_rvmode] = useState("bike");

  const rvmode_s = ["bike", "car"];
  const viewskinsmap =
    nils(activecat) && nils(rvmode)
      ? skinsmap
      : _.filter(
          skinsmap,
          (e) =>
            (nils(rvmode) || e[0] == rvmode) &&
            (nils(activecat) || e[1] == activecat),
        );

  return (
    <div className="relative">
      <Helmet>
        <title>Skin Mint || DNA Racing</title>
      </Helmet>
      <div className="max-h-[calc(800px)] h-[calc(100vh-9rem)] ">
        <div className="h-[2rem]"></div>
        <p className="text-center font-digi text-[1.5rem]">
          {_.capitalize(rvmode)} Skins
        </p>

        <div class="fr-cc resp-gap-2 my-[2rem]">
          {rvmode_s.map((rv) => {
            let active = rv === rvmode;
            return (
              <div
                onClick={() => {
                  set_rvmode(rv);
                }}
                className={twMerge(
                  "cursor-pointer resp-p-2 h-full rounded-md",
                  active ? "bg-acc4 shadow-acc0 shadow-md" : "bg-r2dark/20",
                )}
              >
                <RVImg
                  {...{ rvmode: rv }}
                  hex_code={!active ? "#06DEC3" : "#FFFFFF"}
                  className={"xs:w-[4rem] lg:w-[8rem]"}
                />
              </div>
            );
          })}
        </div>
        <div className="fr-cc gap-2 w-full flex-wrap">
          {[
            "common",
            "uncommon",
            "rare",
            "epic",
            "legendary",
            "ultimate",
            "exclusive",
          ].map((e) => {
            let active = activecat == e;
            return (
              <Tag
                key={e}
                className={twMerge(
                  "transition duration-200 text-white bg-acc4/10",
                  "px-4 text-[1rem] font-digi",
                  active ? "bg-acc4/40 -skew-x-12" : "",
                )}
                onClick={() => {
                  if (active) set_activecat(false);
                  else set_activecat(e);
                }}
              >
                {e == "exclusive" ? `Custom Orders` : _.capitalize(e)}
              </Tag>
            );
          })}
        </div>
        <div className="h-[2rem]"></div>
        <Card className={"w-[40rem] max-w-[98vw] mx-auto bg-reg fr-cc"}>
          <div className="flex-1"></div>
          <Link to="/skinmint">
            <Tag className="bg-acc4/40 shadow-lg shadow-acc4 transform -skew-x-12">
              Mint Skins Now
            </Tag>
          </Link>

          <Link to="/skingame">
            <Tag className="bg-purple-400/40 shadow-lg shadow-purple-400 transform -skew-x-12">
              Skin Game
            </Tag>
          </Link>

          {false && (
            <Link to="/skin-xmas">
              <Tag className="bg-red-400/40 shadow-lg shadow-red-400 transform -skew-x-12">
                X-Mas
              </Tag>
            </Link>
          )}

          {false && (
            <Link to="/skinmint?selboxtype=bredmadness202403">
              <Tag className="bg-red-400/40 shadow-lg shadow-red-400 transform -skew-x-12">
                {"Bred Madness 2024"}
              </Tag>
            </Link>
          )}
        </Card>

        {activecat == "exclusive" && (
          <>
            <div className="h-[0.5rem]"></div>
            <Card className={"w-[40rem] max-w-[98vw] mx-auto bg-acc4/20 fr-cc"}>
              <div className="fr-sc">
                <p className="resp-text---1">
                  {`Contact Novus Dzine for custom orders `}
                  <span className="text-purple-300">
                    <FontAwesomeIcon className="resp-px-1" icon={faDiscord} />
                    leons2025
                  </span>
                </p>
                <div className="flex-1"></div>
              </div>
            </Card>
          </>
        )}

        <div className="h-[2rem]"></div>
        <div
          onMouseLeave={() => set_activeskin(false)}
          className="grid xs:grid-cols-1 md:grid-cols-3 2xl:grid-cols-3  gap-0"
        >
          {viewskinsmap.map((e, idx) => {
            const [rvmode, rarity, skin] = e;
            return (
              <div
                onMouseEnter={() => {
                  set_activeskin(e[2]);
                }}
                className="col-span-1"
              >
                <SkinCard
                  key={skin}
                  {...{ rarity, skin, activeskin, idx, rvmode }}
                />
              </div>
            );
          })}
        </div>
        <div className="h-[10rem]"></div>
      </div>
    </div>
  );
};
